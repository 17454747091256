var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-warning-go-to-reservation-mult-trip",
      "centered": "",
      "title": "Lưu ý",
      "title-class": "font-medium-3 font-weight-bolder"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mt-lg-25 border-0",
          attrs: {
            "variant": "warning",
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('confirm');
            }
          }
        }, [_vm._v(" Tiếp tục đặt vé ")])];
      }
    }])
  }, [_c('b-card-text', {
    staticClass: "my-1"
  }, [_vm._v(" Thời gian hạ cánh và cất cánh giữa các chuyến bay quá sát giờ, xác nhận tiếp tục đặt vé? ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }