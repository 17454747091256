<template>
  <b-modal
    id="modal-warning-go-to-reservation-mult-trip"
    centered
    title="Lưu ý"
    title-class="font-medium-3 font-weight-bolder"
  >
    <template #modal-footer="{close}">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-secondary"
        class="center rounded-pill width-100"
        @click="close()"
      >
        {{ $t('reservation.back') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="mt-lg-25 border-0"
        variant="warning"
        pill
        @click="$emit('confirm')"
      >
        Tiếp tục đặt vé
      </b-button>
    </template>

    <b-card-text class="my-1">
      Thời gian hạ cánh và cất cánh giữa các chuyến bay quá sát giờ, xác nhận tiếp tục đặt vé?
    </b-card-text>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BModal,
    BCardText,
  },
}
</script>
<style lang="">

</style>
